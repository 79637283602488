import type { BoxProps } from '@mui/material/Box';
import type { Breakpoint } from '@mui/material/styles';

import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { useUser } from 'src/hooks/use-user';

import { AUTH } from 'src/lib/firebase';
import { layoutClasses } from 'src/layouts/classes';
// ----------------------------------------------------------------------

type MainProps = BoxProps & {
  layoutQuery: Breakpoint;
};

export function Main({ sx, children, layoutQuery, ...other }: MainProps) {
  const theme = useTheme();

  return (
    <Box
      component="main"
      className={layoutClasses.main}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.neutral,
        [theme.breakpoints.up(layoutQuery)]: {
          flexDirection: 'row',
        },
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

// ----------------------------------------------------------------------

export function Content({ sx, children, layoutQuery, ...other }: MainProps) {
  const theme = useTheme();

  const { fetchUser } = useUser();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(AUTH, (userProfile) => {
      if (userProfile) {
        fetchUser(userProfile.uid);
      } else {
        console.error('User is not logged in');
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      className={layoutClasses.content}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'center',
        flexDirection: 'column',
        p: theme.spacing(3, 2, 10, 2),
        [theme.breakpoints.up(layoutQuery)]: {
          justifyContent: 'center',
          p: theme.spacing(9, 2, 10, 2),
        },
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
