import { useState, useEffect } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { SplashScreen } from 'src/components/loading-screen';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function GuestGuard({ children }: Props) {
  const router = useRouter();

  const { authenticated, loading: loadingAuth, user } = useAuthContext();
  const [isChecking, setIsChecking] = useState<boolean>(true);

  const checkAccess = async (): Promise<void> => {
    if (loadingAuth) {
      return;
    }

    if (authenticated && user) {
      const { role } = user;

      const defaultRoute =
        role === 'Super Admin' ? paths.dashboard.viewAllContests : paths.dashboard.root;

      router.replace(defaultRoute);
      return;
    }

    setIsChecking(false);
  };

  useEffect(() => {
    checkAccess();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, loadingAuth]);

  if (isChecking) {
    return <SplashScreen />;
  }

  return children;
}
