import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';

import { getFirebaseToken } from './changeToReadySaga';
import { reportSubmissionRequest, reportSubmissionSuccess } from '../reducers/reportSubmission';

function* reportSubmissionSaga(
  action: ReturnType<typeof reportSubmissionRequest>
): Generator<any, void, any> {
  const idToken = yield call(getFirebaseToken);
  try {
    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/admin/report-submission`,
      {
        reasonId: action.payload.reasonId,
        submissionId: action.payload.submissionId,
        comment: action.payload.comment,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(reportSubmissionSuccess(response.data));
  } catch (error: any) {
    yield put(reportSubmissionSuccess(error.message || 'Failed to submit report'));
  }
}

export function* watchReportSubmissionSaga() {
  yield takeLatest(reportSubmissionRequest, reportSubmissionSaga);
}
