import type { UserRoles } from 'src/enums/Roles';

import { Navigate } from 'react-router';

import { paths } from 'src/routes/paths';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

export type RoleBasedGuardProp = {
  acceptRoles: UserRoles[];
  children: React.ReactNode;
};

export function RoleBasedGuard({
  children,

  acceptRoles,
}: RoleBasedGuardProp) {
  const { user } = useAuthContext();

  const { role } = user || { role: 'Brand Admin' };

  const defaultRoute =
    role === 'Superadmin' ? paths.dashboard.viewAllContests : paths.dashboard.root;

  if (typeof acceptRoles !== 'undefined' && !acceptRoles.includes(role)) {
    return <Navigate to={defaultRoute} replace />;
  }

  return <> {children} </>;
}
