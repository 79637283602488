import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useNotification } from 'src/components/shared/notifications/notification-snackbar';

export const PaymentFailedNotification = () => {
  const { showNotification } = useNotification();
  const naviagator = useNavigate();

  useEffect(() => {
    const handleSuccess = (message: string) => {
      showNotification(message, { variant: 'error' });
    };

    handleSuccess('Payment failed!');

    naviagator('/dashboard/contests', { replace: true });
  }, [naviagator, showNotification]);

  return null;
};
