import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';

import { getFirebaseToken } from './changeToReadySaga';
import {
  removeSubmissionRequest,
  removeSubmissionFailure,
  removeSubmissionSuccess,
  removeSubmissionSentEmail,
} from '../reducers/removeSubmission';

function* removeSubmissionSaga(
  action: ReturnType<typeof removeSubmissionRequest>
): Generator<any, void, any> {
  const idToken = yield call(getFirebaseToken);
  try {
    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/admin/archive-user-submission`,
      {
        submissionId: action.payload.submissionId,
        reasonId: action.payload.reasonId,
        brandId: action.payload.brandId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(removeSubmissionSuccess(response.data));
  } catch (error: any) {
    yield put(removeSubmissionFailure(error.message || 'Failed to remove submission'));
  }
}

function* removeSubmissionSentEmailSaga(
  action: ReturnType<typeof removeSubmissionSentEmail>
): Generator<any, void, any> {
  const idToken = yield call(getFirebaseToken);
  try {
    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/admin/remove-submission-brand-email`,
      {
        submissionId: action.payload.submissionId,
        reasonId: action.payload.reasonId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(removeSubmissionSuccess(response.data));
  } catch (error: any) {
    yield put(removeSubmissionFailure(error.message || 'Failed to send email'));
  }
}

export function* watchRemoveSubmissionSaga() {
  yield takeLatest(removeSubmissionRequest, removeSubmissionSaga);
  yield takeLatest(removeSubmissionSentEmail, removeSubmissionSentEmailSaga);
}
