import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';

import { getFirebaseToken } from './changeToReadySaga';
import {
  keepSubmissionFailure,
  keepSubmissionRequest,
  keepSubmissionSuccess,
  sendEmailToBrandRequest,
  sendEmailToBrandFailure,
  sendEmailToBrandSuccess,
} from '../reducers/keepSubmissionSlice';

function* keepSubmissionSaga(
  action: ReturnType<typeof keepSubmissionRequest>
): Generator<any, void, any> {
  const idToken = yield call(getFirebaseToken);
  try {
    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/admin/keep-submission-report`,
      {
        reportId: action.payload.reportId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(keepSubmissionSuccess(response.data));
  } catch (error: any) {
    yield put(keepSubmissionFailure(error.message || 'Failed to keep submission'));
  }
}

function* sendEmailToBrandSaga(
  action: ReturnType<typeof sendEmailToBrandRequest>
): Generator<any, void, any> {
  const idToken = yield call(getFirebaseToken);
  try {
    yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/admin/keep-submission-report-email`,
      {
        reportId: action.payload.reportId,
        comment: action.payload.comment,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    yield put(sendEmailToBrandSuccess());
  } catch (error: any) {
    yield put(sendEmailToBrandFailure(error.message || 'Failed to send email to brand'));
  }
}

export function* watchKeepSubmissionSaga() {
  yield takeLatest(keepSubmissionRequest, keepSubmissionSaga);
  yield takeLatest(sendEmailToBrandRequest, sendEmailToBrandSaga);
}
