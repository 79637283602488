import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthSplitLayout } from 'src/layouts/auth-split';
import { AuthProfileLayout } from 'src/layouts/auth-split/profile-layout';

import { SplashScreen } from 'src/components/loading-screen';

import { AuthGuard } from 'src/auth/guard';
import { GuestGuard } from 'src/auth/guard/guest-guard';
// ----------------------------------------------------------------------

const Firebase = {
  SignInPage: lazy(() => import('src/pages/auth/sign-in')),
  SignUpPage: lazy(() => import('src/pages/auth/sign-up')),
  FinishProfile: lazy(() => import('src/pages/auth/finish-profile')),
  VerifyPage: lazy(() => import('src/pages/auth/verify')),
  ResetPasswordPage: lazy(() => import('src/pages/auth/reset-password')),
  BrandTopic: lazy(() => import('src/pages/auth/brand-topics')),
  CreateSubscription: lazy(() => import('src/pages/auth/create-subscription')),
};

const authFirebase = {
  children: [
    {
      path: 'sign-in',
      element: (
        <GuestGuard>
          <AuthSplitLayout>
            <Firebase.SignInPage />
          </AuthSplitLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'sign-up',
      element: (
        <GuestGuard>
          <AuthSplitLayout>
            <Firebase.SignUpPage />
          </AuthSplitLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'finish-profile',
      element: (
        <AuthGuard>
          <AuthProfileLayout>
            <Firebase.FinishProfile />
          </AuthProfileLayout>
        </AuthGuard>
      ),
    },

    // {
    //   path: 'brand-topics',
    //   element: (
    //     <AuthProfileLayout>
    //       <Firebase.BrandTopic />
    //     </AuthProfileLayout>
    //   ),
    // },
    // {
    //   path: 'verify',
    //   element: (
    //     <AuthSplitLayout>
    //       <Firebase.VerifyPage />
    //     </AuthSplitLayout>
    //   ),
    // },

    {
      path: 'reset-password',
      element: (
        <GuestGuard>
          <AuthSplitLayout>
            <Firebase.ResetPasswordPage />
          </AuthSplitLayout>
        </GuestGuard>
      ),
    },

    {
      path: 'create-subscription',
      element: (
        <AuthGuard>
          <AuthSplitLayout>
            <Firebase.CreateSubscription />
          </AuthSplitLayout>
        </AuthGuard>
      ),
    },
  ],
};

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [authFirebase],
  },
];
