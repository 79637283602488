import type { ReactNode } from 'react';

import { Outlet } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { useUser } from 'src/hooks/use-user';

import { AUTH } from 'src/lib/firebase';
import { UserRoles } from 'src/enums/Roles';
import { DashboardLayout } from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

import { PaymentFailedNotification } from 'src/sections/redirects/payment-failed';
import { PaymentSuccessNotification } from 'src/sections/redirects/payment-success';

import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';

const ContestsPage = lazy(() => import('src/pages/dashboard/contests'));
const MyBrandPage = lazy(() => import('src/pages/dashboard/my-brand'));
const AccountSettingsPage = lazy(() => import('src/pages/dashboard/account-settings'));
const PayoutsPage = lazy(() => import('src/pages/dashboard/payouts'));
const UserManagementPage = lazy(() => import('src/pages/super-admin/user-management'));
const ContestDetailsPage = lazy(() => import('src/pages/dashboard/contests/contest-details'));
const CreateContestView = lazy(() => import('src/pages/dashboard/contests/create-new-contest'));
const ViewAllContests = lazy(() => import('src/pages/super-admin/all-contests'));
const EditUserPage = lazy(() => import('src/pages/super-admin/edit-user'));
const BrandPage = lazy(() => import('src/pages/super-admin/brands'));
const BrandManagementPage = lazy(() => import('src/pages/super-admin/brand-management'));
const PendingApprovalPage = lazy(() => import('src/pages/super-admin/tasks/pending-approval'));
const PendingVerificationPage = lazy(
  () => import('src/pages/super-admin/tasks/pending-verification')
);
const ReportedSubmissionsPage = lazy(
  () => import('src/pages/super-admin/tasks/reported-submissions')
);

const DashboardRoutesWrapper = () => {
  const { user, fetchUser } = useUser();

  useEffect(() => {
    if (!user) {
      const unsubscribe = onAuthStateChanged(AUTH, (userProfile) => {
        if (userProfile) {
          fetchUser(userProfile.uid);
        } else {
          console.error('User is not logged in');
        }
      });

      return () => unsubscribe();
    }

    return () => {};
  }, [fetchUser, user]);

  const userRole = user?.role || '';

  return (
    <DashboardLayout>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet context={{ userRole }} />
      </Suspense>
    </DashboardLayout>
  );
};

interface ProtectedRouteProps {
  acceptRoles: UserRoles[];
  children: ReactNode;
}

const ProtectedRoute = ({ acceptRoles, children }: ProtectedRouteProps) => (
  <RoleBasedGuard acceptRoles={acceptRoles}>{children}</RoleBasedGuard>
);

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardRoutesWrapper />
      </AuthGuard>
    ),
    children: [
      {
        path: 'payouts',
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <PayoutsPage />
          </ProtectedRoute>
        ),
        index: true,
      },
      {
        path: 'contests',
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.BRAND_ADMIN]}>
            <ContestsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'contest/:contestId',
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.BRAND_ADMIN, UserRoles.SUPERADMIN]}>
            <ContestDetailsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'contest/create-new-contest',
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.BRAND_ADMIN, UserRoles.SUPERADMIN]}>
            <CreateContestView />
          </ProtectedRoute>
        ),
      },
      {
        path: 'contest/view-all-contests',
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <ViewAllContests />
          </ProtectedRoute>
        ),
      },
      {
        path: 'my-brand',
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.BRAND_ADMIN]}>
            <MyBrandPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'brands',
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <BrandPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'settings',
        element: <AccountSettingsPage />,
      },
      {
        path: 'user-management',
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <UserManagementPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'user-management/:userId',
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <EditUserPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'brand-management/:brandId',
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <BrandManagementPage />
          </ProtectedRoute>
        ),
      },

      {
        path: 'tasks',
        children: [
          {
            path: 'reported-submissions',
            element: (
              <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
                <ReportedSubmissionsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'pending-approval',
            element: (
              <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
                <PendingApprovalPage />
              </ProtectedRoute>
            ),
          },

          // {
          //   path: 'edit-requests',
          //   element: (
          //     <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
          //       <PendingApprovalPage />
          //     </ProtectedRoute>
          //   ),
          // },
          {
            path: 'pending-verification',
            element: (
              <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
                <PendingVerificationPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: '/dashboard/contests/success',
        element: <PaymentSuccessNotification />,
      },
      {
        path: '/dashboard/contests/cancel',
        element: <PaymentFailedNotification />,
      },
    ],
  },
];
