import type { PayloadAction } from '@reduxjs/toolkit';
import type { SubmissionWithUser } from 'src/interfaces/submission-with-user';

import { createSlice } from '@reduxjs/toolkit';

export type SelectedSubmissionType = {
  loading: boolean;
  error: string | null;
  selectedSubmissionId: string | null;
  selectedSubmission: SubmissionWithUser | null;
  selectedReportId: string | null;
  reportId: string | null;
  deleteSubmission: string | null;
};

const initialState: SelectedSubmissionType = {
  loading: false,
  error: null,
  selectedSubmissionId: null,
  selectedSubmission: null,
  selectedReportId: null,
  reportId: null,
  deleteSubmission: null,
};

export const selectedSubmissionSlice = createSlice({
  name: 'selected-submission',
  initialState,
  reducers: {
    setSelectedSubmissionId: (state, action: PayloadAction<string | null>) => {
      state.selectedSubmissionId = action.payload;
    },
    setSelectedSubmissionWithUser: (state, action: PayloadAction<SubmissionWithUser | null>) => {
      state.selectedSubmission = action.payload;
    },
    setSelectedReportId: (state, action: PayloadAction<string | null>) => {
      state.selectedReportId = action.payload;
    },
    updateSelectedSubmission: (state, action: PayloadAction<Partial<SubmissionWithUser>>) => {
      if (state.selectedSubmission) {
        state.selectedSubmission = { ...state.selectedSubmission, ...action.payload };
      }
    },
    markAsDeleted: (state, action: PayloadAction<string | null>) => {
      state.deleteSubmission = action.payload;
    },
  },
});

export const {
  setSelectedSubmissionId,
  setSelectedSubmissionWithUser,
  updateSelectedSubmission,
  setSelectedReportId,
  markAsDeleted,
} = selectedSubmissionSlice.actions;

export default selectedSubmissionSlice.reducer;
