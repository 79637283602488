import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type RemoveSubmissionState = {
  loading: boolean;
  error: string | null;
  submissionId: string | null;
  reasonId: string | null;
  brandId: string | null;
};

const initialState = {
  loading: false,
  error: null,
  submissionId: null,
  reasonId: null,
  brandId: null,
};

export const removeSubmissionSlice = createSlice({
  name: 'remove-submission',
  initialState,
  reducers: {
    removeSubmissionRequest: (
      state,
      action: PayloadAction<{
        submissionId: string;
        reasonId: string;
        brandId: string;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    removeSubmissionSuccess: (state) => {
      state.loading = false;
    },
    removeSubmissionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    removeSubmissionSentEmail: (
      state,
      action: PayloadAction<{
        submissionId: string;
        reasonId: string;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
  },
});

export const {
  removeSubmissionRequest,
  removeSubmissionSuccess,
  removeSubmissionFailure,
  removeSubmissionSentEmail,
} = removeSubmissionSlice.actions;

export default removeSubmissionSlice.reducer;
