import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type ReportSubmissionState = {
  loading: boolean;
  error: string | null;
  reasonId: string | null;
  submissionId: string | null;
  comment: string | null;
};

const initialState = {
  loading: false,
  error: null,
  reasonId: null,
  submissionId: null,
  comment: null,
};

export const reportSubmissionSlice = createSlice({
  name: 'report-submission',
  initialState,
  reducers: {
    reportSubmissionRequest: (
      state,
      action: PayloadAction<{
        reasonId: string;
        submissionId: string;
        comment: string;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    reportSubmissionSuccess: (state, action) => {
      state.loading = false;
      state.reasonId = action.payload;
    },
    reportSubmissionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { reportSubmissionRequest, reportSubmissionSuccess, reportSubmissionFailure } =
  reportSubmissionSlice.actions;

export default reportSubmissionSlice.reducer;
