import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type KeepSubmissionState = {
  loading: boolean;
  error: string | null;
  reportId: string | null;
  comment?: string | null | undefined;
};

const initialState = {
  loading: false,
  error: null,
  reportId: null,
  comment: null,
};

export const keepSubmissionSaga = createSlice({
  name: 'keep-submission',
  initialState,
  reducers: {
    keepSubmissionRequest: (
      state,
      action: PayloadAction<{
        reportId: string;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    keepSubmissionSuccess: (state) => {
      state.loading = false;
    },
    keepSubmissionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    sendEmailToBrandRequest: (
      state,
      action: PayloadAction<{
        reportId: string;
        comment: string;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    sendEmailToBrandSuccess: (state) => {
      state.loading = false;
    },
    sendEmailToBrandFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  keepSubmissionRequest,
  keepSubmissionSuccess,
  keepSubmissionFailure,
  sendEmailToBrandRequest,
  sendEmailToBrandSuccess,
  sendEmailToBrandFailure,
} = keepSubmissionSaga.actions;

export default keepSubmissionSaga.reducer;
