import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { useNotification } from 'src/components/shared/notifications/notification-snackbar';

export const PaymentSuccessNotification = () => {
  const { showNotification } = useNotification();
  const naviagator = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleSuccess = (message: string) => {
      showNotification(message, { variant: 'success' });
    };

    const successParam = searchParams.get('checkout');

    if (successParam === 'subscription') handleSuccess('Subscription activated successfully!');
    else if (successParam === 'contest') handleSuccess('Contest payment was successful!');

    naviagator('/dashboard/contests', { replace: true });
  }, [naviagator, showNotification, searchParams]);

  return null;
};
